import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mentorsLogo from "../img/mentors-logo.png";
import Hadeed from "../img/Hadeed img.avif";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MyContext from "../contexts/Mycontexts";

const Usernav = () => {
  const { signUser, setSignUser } = useContext(MyContext)
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    const onHandleClick = (e) => {
      const collapseElement = document.getElementById("collapseExample")
      if (collapseElement && !collapseElement.contains(e.target)) {
        collapseElement.classList.remove("show")
      }
    }
    document.body.addEventListener("click", onHandleClick)

    return (() => {
      document.body.removeEventListener("click", onHandleClick)
    })
  }, [])

  const logOutButton = () => {
    sessionStorage.removeItem("userSession");
    setSignUser("");
    navigate("/");
  };

  if (location.pathname === "/" || location.pathname === "/verify") {
    return <></>;
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg px-4">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={mentorsLogo} alt="" style={{ width: "170px" }} />{" "}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/course">
                  Courses
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/teacher">
                  Teachers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/school">
                  Schools
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/enrollCourses">
                  Enrolled Courses
                </Link>
              </li>

            </ul>
          </div>
        </div>
        <div className="profile d-flex align-items-center">
          {signUser && signUser.image ? (
            <div className="d-flex">

              <img
                src={signUser.image}
                alt=""
                className="img-fluid me-2"
                style={{ width: "50px", height: '50px', borderRadius: "100px" }}
              />
            </div>
          ) : (
            <div className="d-flex">
              <img
                src={Hadeed}
                alt=""
                className="img-fluid me-2"
                style={{ width: "50px", borderRadius: "100px" }}
              />
            </div>
          )}
          {signUser?.role === "admin" && (
            <DropdownButton
              id="dropdown-basic-button"
              className="user-drop"
              align="start"
              title={signUser && signUser.name.length > 7 ? signUser.name.slice(0, 7) + ".." : signUser.name}
              style={{ backgroundColor: "transparent", color: "#fff", border: "none" }}
            >
              <Dropdown.Item as={Link} to="/adminpanel/dashboard">
                Dashboard
              </Dropdown.Item>


              <Dropdown.Item onClick={logOutButton}>Logout</Dropdown.Item>
            </DropdownButton>
          )}
          {signUser && signUser.role !== "admin" && (
            <DropdownButton
              id="dropdown-basic-button"
              className="user-drop"
              align="start"
              title={
                signUser.name.length > 7 ? `${signUser.name.slice(0, 7)}..` : signUser.name
              }
              style={{ backgroundColor: "transparent", color: "#fff", border: "none" }}
            >
              <Dropdown.Item as={Link} to="/user-panel/user-courses">
                Dashboard
              </Dropdown.Item>
              <Dropdown.Item onClick={logOutButton}>Logout</Dropdown.Item>
            </DropdownButton>
          )}

        </div>
      </nav>

      {/* {/ Profile collapse menu /} */}

    </div>
  );
};

export default Usernav;
