import React, { useContext, useEffect, useState } from "react";
// import FrontendDeveloper from "../img/front-end-developer-course.avif";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import courseEnroll from "../img/course-enrollment.avif";
import registerInstructor from "../img/instructor-register.avif";
import schoolRegister from "../img/school-register.avif";
import { useParams, Link } from "react-router-dom";
import courseContext from "../contexts/CourseContext";
import frontendDeveloper from "../img/Partners/frontdev.avif";
import MyContext from "../contexts/Mycontexts";
import LessonContext from "../contexts/LessonContext";
import EnrolledContext from "../contexts/EnrolledContext";
import Swal from "sweetalert2"

function Coursedetail() {
  const { allCourse, CourseById, getCourse } = useContext(courseContext);
  const { EnrolRequests } = useContext(EnrolledContext)
  const { lessons } = useContext(LessonContext);
  const { signUser } = useContext(MyContext)
  const [Title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const { title } = useParams();
  const pureTitle = title.replace(/-/g, " ");

  const [showMoreLearning, setShowMoreLearning] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(false);

  const ITEM_LIMIT = 4;

  const [enroll, setEnroll] = useState({
    studentId: "",
    courseId: "",
    description: "",
  });

  const Enrollment = async (e) => {
    e.preventDefault();
    const userNameError = document.getElementById("userNameError")
    const userTitleError = document.getElementById("userTitleError")
    const userDescError = document.getElementById("userDescError")

    let errorHandle = false;

    const { courseId, studentId, description } = enroll;

    if (!studentId) {
      userNameError.innerText = "Select course name please"
      errorHandle = true
    } else {
      userNameError.innerText = ""
    }
    if (!courseId) {
      userTitleError.innerText = "Select Course title please"
      errorHandle = true
    } else {
      userTitleError.innerText = ""
    }
    if (errorHandle) {
      return;
    }
    const res = await fetch("https://accademia-backend.vercel.app/api/enrollCourse/enrollments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId, studentId, description }),
    });

    if (res.ok) {
      setEnroll({
        studentId: "",
        courseId: "",
        description: "",
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Enrollment request send successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      EnrolRequests()
    }
  };
  const onchange = (e) => {
    setEnroll({ ...enroll, [e.target.name]: e.target.value });
  };

  const getDetailCourse = async () => {
    const res = await fetch(
      `https://accademia-backend.vercel.app/api/course/getcorse/${Title}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setCategory(data.categoryy);
    setCourse(data.courseTitle);
  };
  const getCorse = async () => {
    const res = await fetch(
      `https://accademia-backend.vercel.app/api/course/getcorse/${pureTitle}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setCategory(data.categoryy);
    setCourse(data.courseTitle);
  };
  useEffect(() => {
    getDetailCourse();
    getCorse();
  }, [pureTitle, Title]);

  const iFrameUrl = (urls) => {
    if (urls.startsWith("https://www.youtube.com/embed")) {
      return urls;
    } else if (urls.includes("https://youtu.be/")) {
      const videoUrls = urls.split("https://youtu.be/");
      return `https://www.youtube.com/embed/${videoUrls[1]}`;
    } else if (urls.includes("https://www.youtube.com/watch?v")) {
      const videoUrls = urls.split("v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoUrls}`;
    }
  };
  return (
    <div style={{ backgroundColor: "#F9F9F9" }}>
      <section className="courses-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="course-head py-5 text-dark">
                <h1 className="">{course.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="course-data py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="overview bg-white p-3 mb-4">
                <h2>Course Overview</h2>
                <p style={{ fontWeight: "initial" }}>{course.description}</p>

                <ul style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}>
                  <li>
                    <CheckCircleIcon
                      className="list-icon"
                      style={{ color: "var(--secondary-color)" }}
                    />
                    &nbsp;Instructor Name:{" "}
                    {course.instructorName ? (
                      <span className="replaceStrong">
                        {" "}
                        {course.instructorName}{" "}
                      </span>
                    ) : (
                      <span className="replaceStrong">
                        {" "}
                        {course.name}{" "}
                      </span>
                    )}

                  </li>
                  <li>
                    <CheckCircleIcon
                      className="list-icon"
                      style={{ color: "var(--secondary-color)" }}
                    />
                    &nbsp;Course Duration:{" "}
                    <span className="replaceStrong"> {course.duration} </span>
                  </li>
                  <li>
                    <CheckCircleIcon
                      className="list-icon"
                      style={{ color: "var(--secondary-color)" }}
                    />
                    &nbsp;Course Category:{" "}
                    <span className="replaceStrong">
                      {" "}
                      {category.category}{" "}
                    </span>
                  </li>
                  {course.days && course.days !== "undefined" && (
                    <li>
                      <CheckCircleIcon
                        className="list-icon"
                        style={{ color: "var(--secondary-color)" }}
                      />
                      &nbsp;Classes In A Week:{" "}
                      <span className="replaceStrong"> {course.days} </span>
                    </li>
                  )}
                  {course.timeSlot && course.timeSlot !== "undefined" && (
                    <li>
                      <CheckCircleIcon
                        className="list-icon"
                        style={{ color: "var(--secondary-color)" }}
                      />
                      &nbsp;Class Timings:{" "}
                      <span className="replaceStrong"> {course.timeSlot} </span>
                    </li>
                  )}
                </ul>
              </div>
              {course.image ? (

              <div className="course-img">
                <img
                  src={course.image}
                  alt="course-img"
                  className="img-fluid"
                />
              </div>
              ): (
                <img
                  src={frontendDeveloper}
                  alt="course-img"
                  className="img-fluid"
                />
              )}
              <div className="row">
                {course.outcome?.length > 0 && (
                  <div className="col-md-12 listing mt-4">
                    <div className="learning-outcomes py-2">
                      <h3>Learning Outcome</h3>
                      <ul
                        style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                      >
                        {course.outcome
                          .map((line, lineindex) => (
                            <li key={lineindex}>
                              <CheckCircleIcon className="list-icon" />
                              &nbsp;{line}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

                {course.target?.length > 0 && (
                  <div className="col-md-12 listing mt-4">
                    <div className="learning-outcomes py-2">
                      <h3>Target Audience</h3>
                      <ul
                        style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                      >
                        {course.target
                          .map((line, lineindex) => (
                            <li key={lineindex}>
                              <CheckCircleIcon className="list-icon" />
                              &nbsp;{line}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

                {course.prereqs?.length > 0 && (
                  <div className="col-md-12 listing mt-4">
                    <div className="learning-outcomes py-2">
                      <h3>Course Prerequisites</h3>
                      <ul
                        style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                      >
                        {course.prereqs
                          .map((line, lineindex) => (
                            <li key={lineindex}>
                              <CheckCircleIcon className="list-icon" />
                              &nbsp;{line}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

              </div>
              <div className="row justify-content-center">
                {course.learning && (
                  <div className="col-md-5 listing mt-4">
                    <div className="learning-outcomes py-2">
                      <h3>{course.moduleName1}</h3>
                      <ul
                        style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                      >
                        {course.learning
                          .split("\n")
                          .slice(0, showMoreLearning ? undefined : ITEM_LIMIT)
                          .map((line, lineindex) => (
                            <li key={lineindex}>
                              <CheckCircleIcon className="list-icon" />
                              &nbsp;{line}
                            </li>
                          ))}

                        <button
                          className="btn btn-link"
                          onClick={() => setShowMoreLearning(!showMoreLearning)}
                        >
                          {showMoreLearning ? "Show less" : "Show more"}
                        </button>
                      </ul>
                    </div>
                  </div>
                )}

                {course.content && (
                  <div className="col-md-5 listing mt-4">
                    <div className="learning-outcomes py-2">
                      <h3>{course.moduleName2}</h3>
                      <ul
                        style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                      >
                        {course.content
                          .split("\n")
                          .slice(0, showMoreContent ? undefined : ITEM_LIMIT)
                          .map((line, lineindex) => (
                            <li key={lineindex}>
                              <CheckCircleIcon className="list-icon" />
                              &nbsp;{line}
                            </li>
                          ))}

                        <button
                          className="btn btn-link"
                          onClick={() => setShowMoreContent(!showMoreContent)}
                        >
                          {showMoreContent ? "Show less" : "Show more"}
                        </button>
                      </ul>
                    </div>
                  </div>
                )}


              </div>
              <div className="row mt-4">
                <div className="col-md-12 text-center p-3">
                  <div className="card listing" style={{ border: "none" }}>
                    <p className="lead" style={{ fontWeight: "550" }}>
                      Ready to Begin Your Journey? Enroll Today and Take the First Step Toward Success!
                    </p>
                    <div>
                      {signUser && signUser.name ? (
                        <button className="second-button" data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop" onClick={() => CourseById(course._id)}>
                          Enroll Now
                        </button>
                      ) : (
                        <Link to="/signin">
                          <button className="second-button">
                            Enroll Now
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 course-cards c1">
              <div className="card enroll-card">
                <Link to="/signin">
                  <div className="enroll-content">
                    <img
                      src={courseEnroll}
                      alt="enroll-in-course"
                      className="img-fluid"
                    />
                    <h3>Want To Enroll In The Course?</h3>
                    <p>
                      Waste no time and be part of the best online learning
                      platform!
                    </p>
                    <button>Enroll Now</button>
                  </div>
                </Link>
              </div>

              <div className="card enroll-card c2">
                <Link to="/signin">
                  <div className="enroll-content">
                    <img
                      src={registerInstructor}
                      alt="register-as-instructor"
                      className="img-fluid"
                    />
                    <h3>Join Today And Teach Globally!</h3>
                    <p>
                      Express your teaching skills in front of students at a
                      bigger stage.
                    </p>
                    <button>Register Now</button>
                  </div>
                </Link>
              </div>
              <div className="card enroll-card c3">
                <Link to="/signin">
                  <div className="enroll-content">
                    <img
                      src={schoolRegister}
                      alt="school-register"
                      className="img-fluid"
                    />
                    <h3>Your School Needs Recognition!</h3>
                    <p>
                      Register your school on Mentors Academia and get found!
                    </p>
                    <button>Register Your School</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <h1>People Also Search For</h1>
          {allCourse &&
            allCourse
              .filter((course) => course.categoryId === category._id)
              .map((course, index) => {
                return (
                  <div className="col-md-3 py-3" key={index}>
                    <Link
                      to={`/course-details/${course.title.replace(/ /g, "-")}`}
                      onClick={() => setTitle(course.title)}
                    >
                      {" "}
                      <div className="card courses">
                        <div className="course-content">
                          {course.image ? (
                            <img src={course.image} alt={`course-img${index}`} style={{height: "170px", width: "100%"}} className="img-fluid"/>
                          ):(
                            <img src={frontendDeveloper} alt="course-featured-image" className="img-fluid" style={{height: "170px", width: "100%"}}/>
                          )}
                        </div>
                        <div className="course-title p-2 mt-2">
                          <h3>{course.title.slice(0, 35) + "..."}</h3>
                          <hr />
                          <p>
                            Duration: <b>{course.duration}</b>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
        </div>
      </div>

      {/* {/ enroll modal /} */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enroll Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={Enrollment}>
                <select
                  className="form-control mt-3"
                  id="Name"
                  name="studentId"
                  value={enroll.studentId}
                  onChange={onchange}
                >
                  <option value="">Select Name</option>
                  {signUser && (
                    <option value={signUser._id}>{signUser.name}</option>
                  )}
                </select>
                <div id="userNameError" className="text-danger"></div>
                <select
                  className="form-control mt-3"
                  id="course Name"
                  name="courseId"
                  value={enroll.courseId}
                  onChange={onchange}
                >
                  <option value="">Select Course</option>
                  <option value={getCourse._id}>{getCourse.title}</option>
                </select>
                <div id="userTitleError" className="text-danger"></div>
                <textarea
                  className="form-control mt-3"
                  name="description"
                  value={enroll.description}
                  onChange={onchange}
                  type="text"
                  placeholder="Description"
                  rows={6}
                />
                <div id="userDescError" className="text-danger"></div>
                <button type="submit" className="btn btn-primary mt-3">
                  Enroll Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coursedetail;