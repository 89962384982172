import React, { useContext, useState } from 'react'
import userContext from '../../contexts/UseContext'
import { useNavigate } from 'react-router-dom'

export default function ResetPassword() {
    const [password, setPassword] = useState("")
    const { veriEmail } = useContext(userContext)

    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const resetPass = async (e) => {
        e.preventDefault()
        if (!password) {
            setErrorMessage("Please enter an new password");
            return;
        }
        if (password) {
            const res = await fetch("http://localhost:8000/api/user/forgotPassword", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: veriEmail, password })
            })
            if (res.ok) {
                navigate("/password-reset")
                setErrorMessage("")
            }
        }
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 py-7">
                        <div className="card p-4" style={{ border: "none", boxShadow: "0 0 4px #D3D3D3" }}>
                            <h3 className='mb-4'>Mentors Academia</h3>
                            <p className='mb-4' style={{ fontSize: "30px" }}>Recover Your <br /> Password</p>
                            <div className='mb-4'>
                                <label htmlFor="password">New Password</label>
                                <input type="password" placeholder='Enter new password' className='form-control' value={password} onChange={(e) => setPassword(e.target.value)} />
                                <div className='text-danger'>{errorMessage}</div>
                                {/* <input type="password" placeholder='Enter password here' className='form-control' value={password} onChange={(e) => setPassword(e.target.value)} />
                                <div className='text-danger'>{errorMessage}</div> */}
                            </div>
                            <button className='btn btn-primary' onClick={resetPass}>Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
