import React, { useContext, useState } from 'react'
import userContext from '../../contexts/UseContext'
import { useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser';

export default function ForgotPassEmailVeri() {
    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const { allUsers, setVeriEmail } = useContext(userContext)

    const handleSendInstructions = () => {
        if (!email) {
            setErrorMessage("Please enter an email address.");
            return;
        }

        const emailExists = allUsers?.find(user => user.email === email);

        if (emailExists) {
            console.log("Instructions sent to:", email);
            localStorage.setItem("verifyEmail", emailExists.email)
            emailjs
                .send(
                    "service_rb04ohy",
                    "template_uz38bjh",
                    {
                        name: emailExists.name,
                        email: emailExists.email,
                    },
                    "SHPDxLrF-3wQqqLD9"
                )
            navigate("/verification")
            setErrorMessage("");
        } else {
            setErrorMessage("Email not found. Please check and try again.");
        }
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 py-7">
                        <div className="card p-4" style={{ border: "none", boxShadow: "0 0 4px #D3D3D3" }}>
                            <h3 className='mb-4'>Mentors Academia</h3>
                            <p className='mb-4' style={{ fontSize: "30px" }}>Recover Your <br /> Password</p>
                            <div className='mb-4'>
                                <label htmlFor="Email">Email</label>
                                <input type="email" placeholder='Enter email here' className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <div className='text-danger'>{errorMessage}</div>
                            </div>
                            <button className='btn btn-primary' onClick={handleSendInstructions}>Send Instructions</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
