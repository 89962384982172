import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import CategoryContext from "../../contexts/CategoryContext";
import AddLessonModal from "../Lesson/AddLessonModal";
import LessonContext from "../../contexts/LessonContext";
import CourseModals from "./CourseModals";
import CourseContext from "../../contexts/CourseContext";
import Accordion from "react-bootstrap/Accordion";
import { Placeholder } from "react-bootstrap";
import MyContext from "../../contexts/Mycontexts";

export default function AddNewCourse() {
  const { category, AllCategory } = useContext(CategoryContext);
  const { signUser } = useContext(MyContext)

  const { deleteCourse, CourseById, allCourse, courseCategory, allCourses } =
    useContext(CourseContext);

  const [searchOption, setSearchOption] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [course, setCourse] = useState({
    title: "",
    duration: "",
    level: "",
    name: "",
    description: "",
    image: "",
    categoryId: "",
  });
  const [outcome, setOutcome] = useState(['']);
  const [prereqs, setPrereqs] = useState(['']);
  const [target, setTarget] = useState(['']);

  // const validationFields = (fields) => {
  //   const titleError = document.getElementById("titleError");
  //   const categoryError = document.getElementById("categoryError");
  //   const durationError = document.getElementById("durationError");
  //   const levelError = document.getElementById("levelError");
  //   const descriptionError = document.getElementById("descriptionError");
  //   const learningError = document.getElementById("learningError");
  //   const instructorError = document.getElementById("instructorError");
  //   let emptyFieldError = false;
  //   if (!fields.categoryId) {
  //     categoryError.innerText = "please enter category";
  //     emptyFieldError = true;
  //   } else {
  //     categoryError.innerText = "";
  //   }
  //   if (!fields.title) {
  //     titleError.innerText = "please enter title";
  //     emptyFieldError = true;
  //   } else {
  //     titleError.innerText = "";
  //   }
  //   if (!fields.duration) {
  //     durationError.innerText = "please enter duration";
  //     emptyFieldError = true;
  //   } else {
  //     durationError.innerText = "";
  //   }
  //   if (!fields.level) {
  //     levelError.innerText = "please confirm level";
  //     emptyFieldError = true;
  //   } else {
  //     levelError.innerText = "";
  //   }
  //   if (!fields.description) {
  //     descriptionError.innerText = "please add description";
  //     emptyFieldError = true;
  //   } else {
  //     descriptionError.innerText = "";
  //   }

  //   if (!fields.instructorName) {
  //     instructorError.innerText = "Instructor Name Missing";
  //     emptyFieldError = true;
  //   } else {
  //     learningError.innerText = "";
  //   }
  //   return emptyFieldError;
  // };

  // add course
  const addCourse = async (e) => {
    e.preventDefault();
    const {
      title,
      duration,
      level,
      description,
      image,
      categoryId,
      name
    } = course;
    // const hasError = validationFields(course);
    // if (hasError) {
    //   return;
    // }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("duration", duration);
    formData.append("level", level);
    formData.append("image", image);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("categoryId", categoryId);
    formData.append("userId", signUser?._id);

    outcome.forEach((item) => formData.append("outcome[]", item));
    prereqs.forEach((item) => formData.append("prereqs[]", item));
    target.forEach((item) => formData.append("target[]", item));

    const res = await fetch(
      "https://accademia-backend.vercel.app/api/course/addcourse",
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await res.json();
    console.log(data);
    if (!res.ok) {
      console.log(`Error: ${res.status} - ${res.statusText}`);
    }
    allCourses();
    setCourse({
      title: "",
      name: "",
      description: "",
      duration: "",
      level: "",
      image: "",
      categoryId: "",
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Course has been created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const onchange = (e) => {
    if (e.target.files) {
      setCourse({ ...course, image: e.target.files[0] });
    } else {
      setCourse({ ...course, [e.target.name]: e.target.value });
    }
  };

  // pagination implement
  const coursePerPage = 16;
  const lastCourseIndex = pageNumber * coursePerPage;
  const firstCourseIndex = lastCourseIndex - coursePerPage;
  const allCorse =
    allCourse &&
    allCourse
      .filter((course) =>
        course.title.toLowerCase().includes(searchOption.toLowerCase())
      )
      .filter((course) => course.categoryId.includes(searchCategory))
      .slice(firstCourseIndex, lastCourseIndex);

  const paginate = (pageNmber) => {
    setPageNumber(pageNmber);
  };

  const handleChangeOutcome = (e, index) => {
    const newOutcome = [...outcome];
    newOutcome[index] = e.target.value;
    setOutcome(newOutcome);
  };
  const handleAddOutcome = () => {
    setOutcome([...outcome, '']);
  };

  const handleChangePrereq = (e, index) => {
    const newPrereqs = [...prereqs];
    newPrereqs[index] = e.target.value;
    setPrereqs(newPrereqs);
  };
  const handleAddPrereq = () => {
    setPrereqs([...prereqs, '']);
  };

  const handleChangeTarget = (e, index) => {
    const newTarget = [...target];
    newTarget[index] = e.target.value;
    setTarget(newTarget);
  };
  const handleAddTarget = () => {
    setTarget([...target, '']);
  };
  return (
    <div className="container mt-3">
      <div className="row d-flex justify-content-center">
        <div className="col-md-3 col-6 mt-2">
          <input
            type="text"
            className="form-control"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            placeholder="Search By Title"
          />
        </div>
        <div className="col-md-3 col-6 mt-2">
          <select
            className="form-control"
            id="course level"
            value={searchCategory}
            onChange={(e) => setSearchCategory(e.target.value)}
          >
            <option value="">Search By Category</option>
            {AllCategory &&
              AllCategory.map((course) => {
                return <option value={course._id}>{course.category}</option>;
              })}
          </select>
        </div>
        <div className="d-flex justify-content-end col-md-5 col-6 mt-2">
          <button
            className="btn btn-primary mb-5"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <i className="fas fa-plus"></i> Add Course
          </button>
        </div>
        <div className="col-md-11">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Lesson</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allCorse &&
                  allCorse.map((course, index) => {
                    return (
                      <tr>
                        <td>{course.title}</td>
                        {courseCategory[index] && (
                          <td>{courseCategory[index].category}</td>
                        )}
                        <td>{course.duration}</td>
                        <td>
                          <AddLessonModal courseIdd={course._id} />
                        </td>
                        <td>
                          <i
                            className="fa fa-eye me-2"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop1"
                            onClick={() => CourseById(course._id)}
                          ></i>
                          <i
                            className="fa fa-pen me-2"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop2"
                            onClick={() => CourseById(course._id)}
                          ></i>
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteCourse(course._id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end my-3">
            {[...Array(Math.ceil(allCourse.length / coursePerPage)).keys()].map(
              (number) => {
                return (
                  <button
                    key={number + 1}
                    className="btn btn-primary mx-1"
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </button>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* {/ {/ course modal /} /} */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="container">
              <div className="modal-body">
                <form
                  className="new-add-course-form"
                  onSubmit={addCourse}
                  encType="multipart/form-data"
                >
                  <h5
                    className="fw-light mb-3"
                    style={{ textDecoration: "underline" }}
                  >
                    Course Introduction
                  </h5>
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-4">
                      <div id="error" className="text-danger text-center"></div>
                      <input
                        name="title"
                        className="form-control"
                        placeholder="Give a catchy course title"
                        type="text"
                        value={course.title}
                        onChange={onchange}
                      />
                    </div>
                    <div className="col-md-4">
                      <div id="error" className="text-danger text-center"></div>
                      <select
                        className="form-control"
                        id="course level"
                        name="categoryId"
                        value={course.categoryId}
                        onChange={onchange}
                      >
                        <option value="">Select Category</option>
                        {category &&
                          category.map((data) => {
                            return (
                              <option value={data._id}>{data.category}</option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <div id="error" className="text-danger text-center"></div>
                      <input
                        name="duration"
                        className="form-control"
                        placeholder="Your course duration"
                        type="text"
                        value={course.duration}
                        onChange={onchange}
                      />
                    </div>
                    <div className="col-md-4">
                      <div id="error" className="text-danger text-center"></div>
                      <select
                        className="form-control mt-3"
                        id="course level"
                        name="level"
                        value={course.level}
                        onChange={onchange}
                      >
                        <option value="">Select Course Level</option>
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                      </select>
                    </div>
                    <div className="col-md-4 mt-3">
                      <div id="error" className="text-danger text-center"></div>
                      <input
                        name="name"
                        className="form-control"
                        value={course.name}
                        onChange={onchange}
                        placeholder="Give your name for public view"
                        type="text"
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <div id="error" className="text-danger text-center"></div>
                      <input
                        name="courseImage"
                        className="form-control"
                        placeholder="A featured image for course"
                        type="file"
                        title="Upload course featured image"
                        value={course.image}
                        onChange={onchange}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <input
                        name="description"
                        className="form-control"
                        type="textarea"
                        placeholder="Tell the a bit about your course"
                        value={course.description}
                        onChange={onchange}
                      />
                    </div>
                  </div>
                  <h5
                    className="fw-light mb-3"
                    style={{ textDecoration: "underline" }}
                  >
                    Intended Learners
                  </h5>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <Accordion defaultActiveKey="">
                        <Accordion.Item className="form-accord" eventKey="0">
                          <Accordion.Header>
                            What will the students learn from your course?
                          </Accordion.Header>
                          <Accordion.Body>
                            {outcome.map((outcomeValue, index) => (
                              <div key={index}>
                                <input
                                  type="tex"
                                  className="form-control mb-3"
                                  name="outcome"
                                  value={outcomeValue}
                                  onChange={(e) => handleChangeOutcome(e, index)}
                                />
                              </div>
                            ))}
                            <div className="">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddOutcome}
                              >
                                + Add more to your response
                              </span>
                              <br />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-12">
                      <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0" className="form-accord">
                          <Accordion.Header>
                            What are the requirements or prerequisites for this
                            course?
                          </Accordion.Header>
                          <Accordion.Body>
                            {prereqs.map((prereqValue, index) => (
                              <div key={index}>
                                <input
                                  className="form-control mb-3"
                                  type="text"
                                  value={prereqValue}
                                  onChange={(e) => handleChangePrereq(e, index)}
                                />
                              </div>
                            ))}
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddPrereq}
                              >
                                + Add more to your response
                              </span>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-12">
                      <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0" className="form-accord">
                          <Accordion.Header>
                            Who is this course for?
                          </Accordion.Header>
                          <Accordion.Body>
                            {target.map((targetValue, index) => (
                              <div key={index}>
                                <input
                                  className="form-control mb-3"
                                  type="text"
                                  value={targetValue}
                                  onChange={(e) => handleChangeTarget(e, index)}
                                />
                              </div>
                            ))}
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddTarget}
                              >
                                + Add more to your response
                              </span>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>

                  <div className="text-center">
                    <button className="first-button mt-3">Add Your Course</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {/ Add Lesson Modal /} */}

      <CourseModals />
    </div>
  );
}
