import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import frontendDeveloper from "../img/front-end-developer-course.avif";
import CourseContext from "../contexts/CourseContext";
import TextComponent from "./TextComponent";
import MyContext from "../contexts/Mycontexts";
import EnrolledContext from "../contexts/EnrolledContext";
import Swal from "sweetalert2";

export default function CoursePage() {
  const { allCourse, CourseById, getCourse } = useContext(CourseContext)
  const { EnrolRequests } = useContext(EnrolledContext)
  const [category, setCategory] = useState([])
  const { signUser } = useContext(MyContext)
  const [searchName, setSearchName] = useState("")
  const [searchCategory, setSearchCategory] = useState("")
  const [enroll, setEnroll] = useState({
    studentId: "",
    courseId: "",
    description: "",
  });

  const Enrollment = async (e) => {
    e.preventDefault();
    const userNameError = document.getElementById("userNameError")
    const userTitleError = document.getElementById("userTitleError")
    const userDescError = document.getElementById("userDescError")

    let errorHandle = false;

    const { courseId, studentId, description } = enroll;

    if (!studentId) {
      userNameError.innerText = "Select course name please"
      errorHandle = true
    } else {
      userNameError.innerText = ""
    }
    if (!courseId) {
      userTitleError.innerText = "Select Course title please"
      errorHandle = true
    } else {
      userTitleError.innerText = ""
    }
    if (errorHandle) {
      return;
    }
    const res = await fetch("https://accademia-backend.vercel.app/api/enrollCourse/enrollments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId, studentId, description }),
    });

    if (res.ok) {
      setEnroll({
        studentId: "",
        courseId: "",
        description: "",
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Enrollment request send successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      EnrolRequests()
    }
  };
  const onchange = (e) => {
    setEnroll({ ...enroll, [e.target.name]: e.target.value });
  };

  const getCategory = async () => {
    const res = await fetch('https://accademia-backend.vercel.app/api/category/getcategory', {
      method: 'GET'
    })
    const data = await res.json()
    setCategory(data)
  }
  useEffect(() => {
    getCategory()
  }, [])
  return (
    <div>
      {/* {/ {/ header start /} /} */}
      <section>
        <div className="head-bg">
          <div className="head-overlay">
            <h1 className="text-center text-white py-7">Courses</h1>
          </div>
        </div>
      </section>
      {/* {/ {/ header end /} /} */}

      <section className="courses-section py-4 bg-white">
        <div className="container">
          <div className="section-header text-center mb-4">
            <h2>Select Courses</h2>
            <h3>Explore Our Popular Courses</h3>
          </div>
          <div className="container mb-4">
            <div className="row find d-flex justify-content-between">
              <div className="col-md-4 mt-4">
                <input
                  type="text"
                  className="form-control schoo-search"
                  placeholder="Search By Title"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>

              <div className="col-md-4 mt-4">
                <select className="form-control" name="" id="" value={searchCategory} onChange={(e) => setSearchCategory(e.target.value)} style={{ backgroundColor: "white" }}>
                  <option value="">Search By Category</option>
                  {category && category.map((cat) => {
                    return <option value={cat._id}>{cat.category}</option>
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row g-4">
            {allCourse && allCourse.filter(course => course.title.toLowerCase().includes(searchName.toLowerCase()))
              .filter(course => course.categoryId.includes(searchCategory))
              .reverse()
              .map((data, index) => {
                return <div className="col-md-4" key={index}>
                  <div className="card courses" style={{ height: '100%' }}>
                    <div className="course-content">
                      <img src={data.image ? data.image : frontendDeveloper} alt={`course-img${index}`} className="img-fluid" style={{ height: "200px", width: "100%" }} />
                    </div>
                    <div className="course-title p-4">
                      <p className="bold-p">{data.title.length > 20 ? data.title.slice(0, 20) + "..." : data.title}</p>
                      <hr />
                      {data.instructorName ? (
                        <p>
                          Instructor Name: <span className="replaceStrong">{data.instructorName}</span>
                        </p>
                      ) : (
                        <p>
                          Instructor Name: <span className="replaceStrong">{data.name}</span>
                        </p>
                      )}
                      <p>
                        Duration: <span className="replaceStrong">{data.duration}</span>
                      </p>
                    </div>
                    <div className="join mb-2">
                      <Link to={`/course-details/${data.title.replace(/ /g, '-')}`}>
                        <button className="first-button">View Contents</button>
                      </Link>
                      {signUser && signUser.name ? (
                        // <Link to="/user-panel/user-courses" >
                        <button className="second-button float-end" data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop" onClick={() => CourseById(data._id)}>
                          Enroll Now
                        </button>
                        // </Link>
                      ) : (
                        <Link to="/signin">
                          <button className="second-button float-end">
                            Enroll Now
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              })}
          </div>
          <TextComponent />
        </div>
      </section>


      {/* {/ enroll modal /} */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enroll Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={Enrollment}>
                <select
                  className="form-control mt-3"
                  id="Name"
                  name="studentId"
                  value={enroll.studentId}
                  onChange={onchange}
                >
                  <option value="">Select Name</option>
                  {signUser && (
                    <option value={signUser._id}>{signUser.name}</option>
                  )}
                </select>
                <div id="userNameError" className="text-danger"></div>
                <select
                  className="form-control mt-3"
                  id="course Name"
                  name="courseId"
                  value={enroll.courseId}
                  onChange={onchange}
                >
                  <option value="">Select Course</option>
                  <option value={getCourse._id}>{getCourse.title}</option>
                </select>
                <div id="userTitleError" className="text-danger"></div>
                <textarea
                  className="form-control mt-3"
                  name="description"
                  value={enroll.description}
                  onChange={onchange}
                  type="text"
                  placeholder="Description"
                  rows={6}
                />
                <div id="userDescError" className="text-danger"></div>
                <button type="submit" className="btn btn-primary mt-3">
                  Enroll Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}