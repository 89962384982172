import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Signup from './components/Signup';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import Signin from './components/Signin';
import Adminpanel from './adminside/Adminpanel';
import Dashboard from './adminside/Dashboard';
import Addcourse from './adminside/Course/Addcourse';
import Addteacher from './adminside/Instructor/Addteacher';
import Addschool from './adminside/School/AddSchools';
import Adduser from './adminside/Users/Addusers';
import TeacherPage from './components/TeacherPage';
import CoursePage from './components/CoursePage';
import SchoolPage from './components/SchoolPage';
import About from './components/About';
import MyProvider from './contexts/Provider';
import StudentPage from './UserSide/StudentPage';
import StudentCourse from './UserSide/StudentCourse';
import CourseEnrollment from './adminside/Course/CourseEnrollment';
import EnrollCourses from './UserSide/Enrollcourses';
import AcceptedRequest from './adminside/Course/AcceptedRequest';
import RejectedRequest from './adminside/Course/RejectedRequest';
import Coursedetail from './components/Coursedetail';
import Schooldetail from './components/Schooldetail';
import Teacherdetails from './components/Teacherdetails';
import Categories from './adminside/Category/AddCategories';
import SchoolRequest from './adminside/School/SchoolRequest';
import TeacherRequest from './adminside/Instructor/TeacherRequest';
import SchoolUser from './schoolUser/Suser';
import RegisterdSchools from './schoolUser/RegisteredSchools';
import Instructor from './InstructorUser/Instructor';
import InstructorRequest from './InstructorUser/InstructorRequest';
import InstructorAproved from './InstructorUser/InstructorAproved';
import ReajectedTeacher from './adminside/Instructor/RejectedTeacher';
import RejectedSchool from './adminside/School/RejectedSchool';
import ScrollTop from './ScrollTop';
import SeoTags from './components/SeoTags';
import UserProfilePage from './Userpanel/UserProfilePage';
import UserProfileCourses from './Userpanel/User Courses/UserProfileCourses';
import UserEnrolledCourses from './Userpanel/User Courses/UserEnrolled Courses';
import InstructorRegistration from './Userpanel/UserProfileInstructor/InstructorRegistration';
import RegistrationDetails from './Userpanel/UserProfileInstructor/RegistrationDetails';
import SchoolRegistrationDetails from './Userpanel/SchoolsOnUserProfile/SchoolRegistrationDetails';
import RegisterSchool from './Userpanel/SchoolsOnUserProfile/RegisterSchool';
import CourseProvider from './contexts/CourseProvider';
import InstructorProvider from './contexts/InstructorProvider';
import SchoolProvider from './contexts/SchoolProvider';
import CategoryProvider from './contexts/CategoryProvider';
import LessonProvider from './contexts/LessonProvider';
import EnrolledCoursePage from './Userpanel/User Courses/EnrolledCoursePage';
import AllLessons from './adminside/Lesson/AllLessons';
import EnrolledProvider from './contexts/EnrolledProvider';
import UserProvider from './contexts/UserProvider';
import InstructorAddCourse from './Userpanel/UserProfileInstructor/InstructorAddCourse';
import InstructorLessons from './Userpanel/UserProfileInstructor/InstructorLessons';
import AddNewCourse from './adminside/Course/AddNewCourse';
import MentorsLoginNavbar from './components/MentorsLoginNavbar';
import { useContext } from 'react';
import MyContext from './contexts/Mycontexts';
import Verify from './components/Verify';
import ForgotPassEmailVeri from './components/ForgotPassword/ForgotPassEmailVeri';
import EmailVerifyMsj from './components/ForgotPassword/EmailVerifyMsj';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import PasswordReset from './components/ForgotPassword/PasswordReset';
import UserProfile from './Userpanel/Userzprofile';

function App() {
  return (
    <div>
      <Router>
        <MyProvider>
          <CourseProvider>
            <InstructorProvider>
              <SchoolProvider>
                <CategoryProvider>
                  <LessonProvider>
                    <EnrolledProvider>
                      <UserProvider>
                        <MentorsLoginNavbar />
                        <SeoTags />
                        <ScrollTop />
                        <AppRoutes />
                        <Footer />
                      </UserProvider>
                    </EnrolledProvider>
                  </LessonProvider>
                </CategoryProvider>
              </SchoolProvider>
            </InstructorProvider>
          </CourseProvider>
        </MyProvider>
      </Router>
    </div>
  );
}

function AppRoutes() {
  const { signUser } = useContext(MyContext)
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/signup' element={<Signup />} />
      <Route path='/signin' element={<Signin />} />
      <Route path='/teacher' element={<TeacherPage />} />
      <Route path='/course' element={<CoursePage />} />
      <Route path='/about' element={<About />} />
      <Route path='/verify' element={<Verify />} />
      <Route path='/forgot-password' element={<ForgotPassEmailVeri />} />
      <Route path='/verification' element={<EmailVerifyMsj />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/password-reset' element={<PasswordReset />} />
      <Route path='/school' element={<SchoolPage />} />
      <Route path='/school-details/:name' element={<Schooldetail />} />
      <Route path='/course-details/:title' element={<Coursedetail />} />
      <Route path='/teacher-details/:id' element={<Teacherdetails />} />
      {signUser && signUser.role === "admin" && (
        <Route path='/adminpanel' element={<Adminpanel />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='add-new-course' element={<AddNewCourse />} />
          <Route path='category' element={<Categories />} />
          <Route path='course' element={<Addcourse />} />
          <Route path='teacher' element={<Addteacher />} />
          <Route path='teacherRequest' element={<TeacherRequest />} />
          <Route path='school' element={<Addschool />} />
          <Route path='schoolRequest' element={<SchoolRequest />} />
          <Route path='acceptedRequest' element={<AcceptedRequest />} />
          <Route path='rejectedRequest' element={<RejectedRequest />} />
          <Route path='users' element={<Adduser />} />
          <Route path='courseenroll' element={<CourseEnrollment />} />
          <Route path='rejectedSchool' element={<RejectedSchool />} />
          <Route path='rejectedTeacher' element={<ReajectedTeacher />} />
          <Route path='all-lessons' element={<AllLessons />} />
        </Route>
      )}

      {signUser && signUser.name && (
        <Route path='/user-panel' element={<UserProfilePage />} >
          <Route index element={<Navigate to="user-courses" />} />
          <Route path='user-courses' element={<UserProfileCourses />} />
          <Route path='user-enrolled-courses' element={<UserEnrolledCourses />} />
          <Route path='instructor-registration' element={<InstructorRegistration />} />
          <Route path='user-profile' element={<UserProfile />} />
          <Route path='registration-details' element={<RegistrationDetails />} />
          <Route path='register-school' element={<RegisterSchool />} />
          <Route path='school-registration-details' element={<SchoolRegistrationDetails />} />
          <Route path='instructor-course' element={<InstructorAddCourse />} />
          <Route path='instructor-lessons' element={<InstructorLessons />} />
        </Route>
      )}
      <Route path='/enrolled-course-page/:title' element={<EnrolledCoursePage />} />
      <Route path='/studentpage' element={<StudentPage />} >
        <Route index element={<Navigate to="studentcourse" />} />
      </Route>
      <Route path='/enrollCourses' element={<EnrollCourses />} />
      <Route path='/studentcourse' element={<StudentCourse />} />
      <Route path='/schoolUser' element={<SchoolUser />} >
        <Route index element={<Navigate to="registerSchool" />} />
        <Route path='registerSchool' element={<RegisterSchool />} />
        <Route path='registerdSchools' element={<RegisterdSchools />} />
      </Route>
      <Route path='/instructorUser' element={<Instructor />}>
        <Route index element={<Navigate to="instructorRequest" />} />
        <Route path='instructorRequest' element={<InstructorRequest />} />
        <Route path='instructorDetail' element={<InstructorAproved />} />
      </Route>
    </Routes>
  )
}
export default App;
