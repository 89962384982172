import React from 'react'
import Alert from "../img/Partners/exclamation.png"

function Verify() {
  return (
    <div>
      <section className='verify-message py-7'>
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-9 text-center">
                    <img src={Alert} alt="verify-your-email" className="img-fluid mb-2" style={{height: "45px", width: "45px"}}/><br/>
                    <p className="text-warning lead">You're almost there&#33; Your acccount needs verification. Visit your email to verify your account and then log back in.</p>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Verify
