import React, { useEffect, useState } from "react";
import LessonAccord from "./LessonAccord";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";

function EnrolledCoursePage() {
  const { title } = useParams();
  const pureTitle = title.replace(/-/g, " ");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");

  const getCourse = async () => {
    const res = await fetch(
      `https://accademia-backend.vercel.app/api/course/getcorse/${pureTitle}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setCategory(data.categoryy);
    setCourse(data.courseTitle);
  };

  useEffect(() => {
    getCourse();
  }, []);
  const iFrameUrl = (urls) => {
    if (urls.startsWith("https://www.youtube.com/embed")) {
      return urls;
    } else if (urls.includes("https://youtu.be/")) {
      const videoUrls = urls.split("https://youtu.be/");
      return `https://www.youtube.com/embed/${videoUrls[1]}`;
    } else if (urls.includes("https://www.youtube.com/watch?v")) {
      const videoUrls = urls.split("v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoUrls}`;
    }
  };
  if (course.lessons && course.lessons.length > 0) {
    return (
      <div>
        <section className="py-4">
          <div className="head-bg">
            <div className="head-overlay">
              <h1 className="text-center py-6 text-white">{course.title}</h1>
            </div>
          </div>
        </section>
 
        <section className="enrolled-course-description">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
              <div className="section-heading">
                <h2>Course Overview</h2>
              </div>
                <p>{course.description}</p>
              </div>
              <div className="section-heading">
                <h2>Lessons</h2>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <Accordion defaultActiveKey="0">
            {course.lessons &&
              course.lessons.map((enrolled, index) => {
                return (
                  <Accordion.Item key={index} eventKey={`${index}`}>
                    <Accordion.Header>{enrolled.title}</Accordion.Header>
                    <Accordion.Body>
                      <section>
                        <div className="row">
                          <div className="col-md-9 mb-4">
                            {enrolled.description}
                          </div>
                          {enrolled.videoUrls &&
                            enrolled.videoUrls.map((urls, index) => {
                              return (
                                <div key={index} className="col-md-4">
                                  <iframe
                                    src={iFrameUrl(urls)}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              );
                            })}
                        </div>
                      </section>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>
      </div>
    );
  } else {
    return <div>
      <section>
        <div className="head-bg">
          <div className="head-overlay">
            <h1 className="text-center py-6 text-white">{course.title}</h1>
          </div>
        </div>
      </section>
      <section className="enrolled-course-description py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="section-heading">
                <h2>Course Overview</h2>
              </div>
              <p>{course.description}</p>
              <div className="section-heading">
                <h2>Lessons</h2>
              </div>
              <h5 className="b-1">The instructor has not added any lesson to this course yet.</h5>
            </div>
          </div>
        </div>
      </section>
    </div>;
  }
}

export default EnrolledCoursePage;
