import React, { useContext } from 'react'
import userContext from '../contexts/UseContext'
import Usernav from './Usernav'
import Navbar from './Navbar'
import MyContext from '../contexts/Mycontexts'
function MentorsLoginNavbar() {
    const {signUser} = useContext(MyContext)
  return (
    <>
      {signUser && signUser.name ? (
        <Usernav />
      ): (
        <Navbar />
      )}
    </>
  )
}

export default MentorsLoginNavbar
