import React, { useContext, useState } from 'react'
import userContext from '../../contexts/UseContext'
import { Link } from 'react-router-dom'

export default function PasswordReset() {

    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 py-7">
                        <div className="card p-4" style={{ border: "none", boxShadow: "0 0 10px #D3D3D3" }}>
                            <h3 className='mb-4'>Mentors Academia</h3>
                            <p className='mb-4' style={{ fontSize: "30px" }}>Recover Your <br /> Password</p>
                            <p className='mb-4'>Your password has been changed successfully.</p>
                            <Link to="/signin"> <button className='btn btn-primary' style={{ width: "100%" }}>Go to sign in</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
